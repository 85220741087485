import React from 'react';
import { graphql } from 'gatsby';
import { shape, arrayOf, any, string } from 'prop-types';

import PostListing from '../components/PostListing';
import PostsContainer from '../components/PostsContainer';
import Layout from '../components/layout';

export default function TagPage({ data, pathContext }) {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <h2>
        Posts in category:
        {pathContext.tag}
      </h2>
      <PostsContainer>
        {posts.map(
          ({
            node: {
              timeToRead,
              excerpt,
              frontmatter: { path, tags, title, date },
            },
          }) => (
            <PostListing
              key={path}
              timeToRead={timeToRead}
              path={path}
              tags={tags}
              title={title}
              excerpt={excerpt}
              date={date}
            />
          )
        )}
      </PostsContainer>
    </Layout>
  );
}

TagPage.propTypes = {
  data: shape({ allMarkdownRemark: shape({ edges: arrayOf(any) }) }).isRequired,
  pathContext: string.isRequired,
};

export const pageQuery = graphql`
  query TagPageQuery($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(fromNow: true)
            path
            title
            tags
          }
          timeToRead
        }
      }
    }
  }
`;
